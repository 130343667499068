import logo from './logo.svg';
import './App.css';
import '@shopify/polaris/build/esm/styles.css';
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { 
  AppProvider,
  Frame,
  Heading,
  Layout,
  Page,
  Stack,
} from '@shopify/polaris';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import enTranslations from '@shopify/polaris/locales/en.json';

import {
  Provider,
  RoutePropagator,
  TitleBar,
  useAppBridge,
} from "@shopify/app-bridge-react";

import { 
  Link,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";

import OrdersOverviewProduct from './pages/OrdersOverviewProduct';
import PurchasedProducts from './routes/PurchasedProducts';

// Page components:
import Billing from './pages/Billing';
import Home from './pages/Home';
import NewOrders from './pages/NewOrders';
import NftSettings from './pages/NftSettings';
import Order from './pages/NewOrders/Order';
import Product from './pages/NewOrders/Order/Product';
import ProductSetup from './pages/ProductSetup';
import SmartContract from './pages/SmartContract';
import TestNft from './pages/TestNft';
import TestNftOrder from './pages/TestNft/Order';
import TestNftProduct from './pages/TestNft/Order/Product';

// Organisms:
import NavMenu from './components/organisms/NavMenu';



function MyProvider(props) {
  const app = useAppBridge();
  const shop = props.shop;
  // console.log('app::2022-06-01', app);

  const location = useLocation();

  // console.log('location', location);


  return (
    <>
      <ApolloProvider client={props.client}>
        <Page>

          <NavMenu app={app}></NavMenu>
          
          <div>
            {/* <TestData></TestData> */}
            <RoutePropagator location={location}></RoutePropagator>
            <Routes>
              <Route path="/" element={<Home app={app} shop={shop} />}></Route>
              <Route path="billing" element={<Billing app={app} location={location} />} />
              <Route path="nft_settings" element={<NftSettings app={app} shop={shop} />} />
              <Route path="product_setup" element={<ProductSetup app={app} shop={shop} />} />
              <Route path="new_orders" element={<NewOrders app={app} shop={shop} />} />
              <Route path="new_orders/:orderId" element={<Order app={app} shop={shop} />} />
              <Route path="new_orders/:orderId/product/:productId" element={<Product app={app} shop={shop} />} />
              <Route path="orders/:orderId/overview/:productId/product" element={<OrdersOverviewProduct app={app} />} />
              <Route path="purchased_products" element={<PurchasedProducts app={app} />} />
              <Route path="smart_contract" element={<SmartContract app={app} shop={shop} />} />
              <Route path="test_nft" element={<TestNft app={app} shop={shop} />} />
              <Route path="test_nft/:orderId" element={<TestNftOrder app={app} shop={shop} />} />
              <Route path="test_nft/:orderId/product/:productId" element={<TestNftProduct app={app} shop={shop} />} />
            </Routes>
          </div>
        </Page>
      </ApolloProvider>
    </>
  );
}


function App() {
  const client = new ApolloClient({
    link: new HttpLink({
      credentials: 'same-origin',
      fetch: authenticatedFetch(window.app), // created in shopify_app.js
      uri: '/graphql'
    }),
    cache: new InMemoryCache()
  });


  const queryParams = new URLSearchParams(window.location.search);
  const shop = queryParams.get('shop');
  const host = queryParams.get('host');
  // console.log('shop', shop);
  // console.log('host', host);


  return (
    <AppProvider i18n={enTranslations}>
      <Provider
        config={{
          apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
          host: host,
          forceRedirect: true,
        }}
      >
        <Frame>
          <MyProvider client={client} shop={shop}></MyProvider>
        </Frame>
      </Provider>
    </AppProvider>
  );
}

export default App;
