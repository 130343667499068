import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  DataTable,
  Layout,
  Page,
} from "@shopify/polaris";

// CSS:
import styles from "./Template.module.css";



function Template(props) {
  // console.log("Template::2022-07-27");
  // console.log("props::2022-07-27", props);

  // Props:
  const storeOrder = props.storeOrder;
  const tableRows = props.tableRows;

  // Prop functions:
  const sumTotalProducts = props.sumTotalProducts;

  var orderEmail = storeOrder.email || 'N/A';


  return (
    <>
      <Page>

        <Layout>
          {/* Page Header */}
          <Layout.Section>
            <div className={styles.headerSection}>
              <div style={{marginRight: '12px'}}>
                <Link to={`/new_orders`} style={{textDecoration: 'none'}}>
                  <Button>Back</Button>
                </Link>
              </div>
              <div style={{marginRight: '12px'}}>
                <p>New Orders / Order #{storeOrder.payload_order.order_number}</p>
              </div>
            </div>
          </Layout.Section>



          <Layout.Section>
            <Card title="Order Details" sectioned>
              <p>
                <b>Order: </b>
                #{storeOrder.payload_order.order_number}
              </p>
              <p>
                <b>Date: </b>
                {storeOrder.my_created_at}
              </p>
              <p>
                <b>Customer: </b>
                {`${storeOrder.my_customer}`}
              </p>
              <p>
                <b>Email: </b>
                { orderEmail }
              </p>
              <p>
                <b>Total Items: </b>
                {sumTotalProducts(
                  storeOrder.payload_order.line_items
                )}
              </p>
              <p>
                <b>Currency: </b>
                {storeOrder.payload_order.currency}
              </p>
              <p>
                <b>Payment Status: </b>
                {storeOrder.my_payment_status}
              </p>
            </Card>
          </Layout.Section>



          <Layout.Section>
            <Card title="Products in this order" sectioned>
              <Page>
                <Card>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text", // Claim status
                      "text",
                    ]}
                    headings={[
                      "Product",
                      "Price",
                      "Claim Status",
                      "Action",
                    ]}
                    rows={tableRows}
                  />
                </Card>
              </Page>
            </Card>
          </Layout.Section>



        </Layout>
      </Page>
    </>
  );

}

export default Template;