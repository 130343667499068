import React from "react";

import {
  Card,
  Layout,
  Page,
} from "@shopify/polaris";

// Components:
import AppUsage from "./AppUsage";
import BillingOverview from "./BillingOverview";
import BillingPeriod from "./BillingPeriod";
import Plans from "./Plans";
import CurrentPlan from "./CurrentPlan";
import Spinner from "../../components/atoms/Spinner";



function Template(props) {
  // console.log("Template::2022-09-26");
  // console.log("props::2022-09-26", props);
  const app = props.app;

  const isLoading = props.isLoading;
  const plan = props.plan;
  const plans = props.plans;
  const store = props.store;

  // State variables:
  const billingActive = props.billingActive;



  // Loading:
  if (isLoading) return (
    <Page>
      <Layout>
        <Spinner></Spinner>
      </Layout>
    </Page>
  );
  if (store === null) return (
    <Page>
      <Layout>
        <Spinner></Spinner>
      </Layout>
    </Page>
  );



  if (billingActive == false) {
    return (
      <>
        <Page>

          <Layout>

            <Layout.Section>
              <Card sectioned>
                <p><b>No plan selected.</b></p>
                <p>Please select a plan below to get started.</p>
              </Card>
            </Layout.Section>

            <Layout.Section>
              <Card sectioned>
                <p>The Digital Collectible Base Cost is the amount you'll be charged when a Digital Collectible Claim Link is generated. We recommend setting the price of your Digital Collectible Product offerings to at minimum match this cost if you wish to remain cost-neutral on each Digital Collectible sale.</p>
              </Card>
            </Layout.Section>

            <Layout.Section>
              <Plans 
                app={app}
                plan={plan}
                plans={plans}
                store={store}
              ></Plans>
            </Layout.Section>
          </Layout>

        </Page>
      </>
    );
  }


  return (
    <>
      <Page>

        <Layout>

          <Layout.Section>
            <Card sectioned>
              <p><b>Your current plan: { plan.name }</b></p>
              <p>Plan started on { plan.activated_on }.</p>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <Card sectioned>
              <p>The Digital Collectible Base Cost is the amount you'll be charged when a Digital Collectible Claim Link is generated. We recommend setting the price of your Digital Collectible Product offerings to at minimum match this cost if you wish to remain cost-neutral on each Digital Collectible sale.</p>
            </Card>
          </Layout.Section>


          <Layout.Section>
            <Plans
              app={app}
              billingActive={billingActive}
              plan={plan}
              plans={plans}
            ></Plans>
          </Layout.Section>
    
          {/* <Layout.Section>
            <BillingOverview app={app}></BillingOverview>
          </Layout.Section>

          <Layout.Section>
            <BillingPeriod app={app}></BillingPeriod>
          </Layout.Section>

          <Layout.Section>
            <AppUsage app={app}></AppUsage>
          </Layout.Section> */}
        </Layout>

      </Page>
    </>
  );

}

export default Template;