import React from "react";

import {
  Card,
  ResourceList,
  Avatar,
  ResourceItem,
  TextStyle,
} from "@shopify/polaris";

// Components:


// Images:



function ResourceListWithBulkActions2(props) {
  const resourceList2 = props.resourceList2;

  const resourceName = resourceList2.resourceName;
  const items = resourceList2.items;
  const promotedBulkActions = resourceList2.promotedBulkActions;
  const bulkActions = resourceList2.bulkActions;
  const selectedItems = resourceList2.selectedItems;

  // Functions:
  const setResourceList2SelectedItems = resourceList2.setResourceList2SelectedItems;

  

  return (
    <Card>
      <ResourceList
        resourceName={resourceName}
        items={items}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={setResourceList2SelectedItems}
        promotedBulkActions={promotedBulkActions}
        // bulkActions={bulkActions}
      />
    </Card>
  );

  function renderItem(item) {
    const { id, url, name, location } = item;
    const media = <Avatar customer size="medium" name={name} />;

    return (
      <ResourceItem
        id={id}
        // url={url}
        media={media}
        accessibilityLabel={`View details for ${name}`}
      >
        <h3>
          <TextStyle variation="strong">{name}</TextStyle>
        </h3>
        <div>{location}</div>
      </ResourceItem>
    );
  }
}





export default ResourceListWithBulkActions2;