import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

// CSS:
import styles from "./css/NavMenuItem.module.css";


function NavMenuItem(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const name = props.name;
  const path = props.path;

  let myStyles = styles.menuHeadingBorder;
  if (location.pathname === path) myStyles = styles.menuHeadingBorderActive;

  return (
    <div className={myStyles}>
      <button
        className={styles.menuHeadingButton}
        onClick={() => {navigate(path)}}
      >
        { name }
      </button>
    </div>
  );
}



export default NavMenuItem;
