import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";
import {
  Button,
  Caption,
  Card,
  DropZone,
  Stack,
  Thumbnail,
} from "@shopify/polaris";
import styles from "../../pages/OrdersOverviewProduct/OrdersOverviewProduct.module.css";
import { NoteMinor } from '@shopify/polaris-icons';



const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function ManualFulfilmentImages(props) {
  const app = props.app;
  const order = props.order;
  const product = props.product;
  var productImage = {}

  if (product.image_details === undefined) {
    return (
      <>Loading...</>
    );
  }

  if (product.image_details === null) {
    productImage.url = "...";
    var displayImage = (
      <>
        <p>
          <b>Image:</b> Please upload an image
        </p>
      </>
    );
    
  } else {
    productImage.url = `https://${product.image_details.base_url}/${product.image_details.file_path}`;
    var displayImage = (
      <>
        <p>
          <b>Image:</b> { productImage.url }
        </p>
        <div className={styles.productImageDiv}>
          <img
            src={ productImage.url }
            className={styles.productImage}
          ></img>
        </div>
      </>
    );
  }

  return (
    <>
      <Card title="Image Management" sectioned>

        { displayImage }

        <div>
          <p>
            Want to use a different image? Upload a new image below.
          </p>
        </div>

        <div>
          <DropZone1 onFileUpload={props.onFileUpload}></DropZone1>
        </div>

        <div>
          <p>Clear the uploaded image using the follow button:</p>
          <Button destructive onClick={handleClearImage}>Clear Image</Button>
        </div>

      </Card>
    </>
  );

  function DropZone1(props) {
    const [file, setFile] = useState();
    const onFileUpload = props.onFileUpload;
  
    const handleDropZoneDrop = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) => {
        setFile((file) => acceptedFiles[0]);
  
        var formData = new FormData();
        formData.append("myFile", acceptedFiles[0], acceptedFiles[0].name);
        formData.append("name", "Kain");
        formData.append("product_uuid", product.uuid);
        formData.append("shopify_order_id", order.shopify_order_id);
  
        const url = "/api/v0/shopify/products/update_image_details";
        let myUrl = new URL(url, BASE_URL_RAILS);
    
        getSessionToken(app).then((data) => {
          let token = data;
  
          var config = {
            method: "POST",
            url: myUrl,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
            data: formData,
          };
  
          axios(config)
            .then(function (response) {
              onFileUpload(response.data.order_product);
            })
            .catch(function (error) {
              console.log(error);
            });
        });
  
      },
      []
    );
  
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  
    const fileUpload = !file && <DropZone.FileUpload />;
    const uploadedFile = file && (
      <Stack>
        <Thumbnail
          size="small"
          alt={file.name}
          source={
            validImageTypes.includes(file.type)
              ? window.URL.createObjectURL(file)
              : NoteMinor
          }
        />
        <div>
          {file.name} <Caption>{file.size} bytes</Caption>
        </div>
      </Stack>
    );
  
    return (
      <>
        <DropZone allowMultiple={false} onDrop={handleDropZoneDrop}>
          {uploadedFile}
          {fileUpload}
        </DropZone>
      </>
    );
  }


  function handleClearImage() {
    getSessionToken(app).then((data) => {
      let token = data;

      const url = `/api/v0/shopify/products/update_image_details_clear`;
      let myUrl = new URL(url, BASE_URL_RAILS);

      var config = {
        method: 'post',
        url: myUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {product_uuid: product.uuid}
      }

      axios(config).then((response) => {
        props.onClearImage(response.data.payload.product);
      })

    });
  }


}

export default ManualFulfilmentImages