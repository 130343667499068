import React from "react";

import {
  Card,
  ResourceList,
  Avatar,
  ResourceItem,
  TextStyle,
} from "@shopify/polaris";

// Components:


// Images:



function ResourceListWithBulkActions(props) {
  const resourceList1 = props.resourceList1;

  const resourceName = resourceList1.resourceName;
  const items = resourceList1.items;
  const promotedBulkActions = resourceList1.promotedBulkActions;
  const bulkActions = resourceList1.bulkActions;
  const selectedItems = resourceList1.selectedItems;

  // Functions:
  const setResourceList1SelectedItems = resourceList1.setResourceList1SelectedItems;

  

  return (
    <Card>
      <ResourceList
        resourceName={resourceName}
        items={items}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={setResourceList1SelectedItems}
        promotedBulkActions={promotedBulkActions}
        // bulkActions={bulkActions}
      />
    </Card>
  );

  function renderItem(item) {
    const { id, url, name, location } = item;
    const media = <Avatar customer size="medium" name={name} />;

    return (
      <ResourceItem
        id={id}
        // url={url}
        media={media}
        accessibilityLabel={`View details for ${name}`}
      >
        <h3>
          <TextStyle variation="strong">{name}</TextStyle>
        </h3>
        <div>{location}</div>
      </ResourceItem>
    );
  }
}





export default ResourceListWithBulkActions;