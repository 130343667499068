import React from "react";
import {
  Card,
  DataTable,
  Page,
} from "@shopify/polaris";

// Components:



function Template(props) {
  // console.log("Template::2022-06-27");
  // console.log("props::2022-06-27", props);
  const tableRows = props.tableRows;



  return (
    <>
      <Page>



        <Card title="New Orders" sectioned>
          <p>Open an order to view the products inside and set up Digital Collectible offerings.</p>
        </Card>



        <Card>
          <DataTable
            columnContentTypes={[
              "numeric",
              "text",
              "text",
              "numeric",
              "text",
            ]}
            headings={[
              "Order",
              "Date",
              "Email",
              "Total Items",
              "Action",
            ]}
            rows={tableRows}
          />
        </Card>



      </Page>
    </>
  );

}

export default Template;