import React from "react";

import {
  Button,
  Card,
  Form,
  FormLayout,
  Image,
  Layout,
  TextField,
} from "@shopify/polaris";

// Images:
import imageSmartContract2DisplayExample from "./images/smart-contract-2-display-example.png"



function SmartContractDetailsDeployed(props) {
  // console.log('SmartContractDetailsDeployed:::2022-08-05');
  // console.log('props:::2022-08-05', props);
  // Variables:
  const smartContract = props.smartContract;

  // Smart contract details:
  const contractName = props.contractName;
  const contractSymbol = props.contractSymbol;


  if (smartContract === null || !['contract deployed', 'deployed'].includes(smartContract.contract_step)) {
    return (
      <></>
    );
  }



  return (
    <>

      {/* Smart contract status */}
      <Layout.Section>
        <Card title="Smart Contract (Deployed)" sectioned>
        <p>Your store is ready to set up Digital Collectible Products.</p>
        </Card>
      </Layout.Section>



      <Layout.Section>
        <Card title="Smart Contract Details" sectioned>
          <p>This is how your store will be represented on the blockchain.</p>

          <Form>
            <FormLayout>

              <TextField
                name="contractAddress"
                value={smartContract.contract_address}
                label="Contract Address"
                type="text"
                disabled
                helpText={(
                  <>
                    <ul>
                      <li>Where all your store's Digital Collectible transactions are listed.</li>
                    </ul>
                  </>
                )}
              />

              <TextField
                name="contractName"
                value={contractName}
                label="Contract Name"
                type="text"
                disabled
                helpText={(
                  <>
                    <ul>
                      <li>Dsiplays on Polygonscan, OpenSea and on your Digital Collectible frames.</li>
                    </ul>
                  </>
                )}
              />

              <TextField
                name="contractSymbol"
                value={contractSymbol}
                label="Contract Symbol"
                type="text"
                disabled
                helpText={
                  <ul>
                    <li>Your one to three-letter code appended to your Contract Name which identifies your store on Polygonscan.</li>
                  </ul>
                }
              />

              <Button primary disabled>Smart Contract Deployed</Button>
            </FormLayout>
          </Form>
        </Card>
      </Layout.Section>



      {/* Display examples */}
      <Layout.Section secondary>
        <Card title="Display examples" sectioned>
          <p>Smart Contract details will display on Polygonscan, OpenSea and your Digital Collectibles.</p>

          <div style={{paddingTop: '30px'}}>
            <Image
              src={imageSmartContract2DisplayExample}
              width="100%"
            ></Image>
          </div>
        </Card>
      </Layout.Section>

    </>
  );

}



export default SmartContractDetailsDeployed;