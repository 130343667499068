import React from "react";

import {
  Button,
  Card,
  Form,
  FormLayout,
  Image,
  Layout,
  TextField,
} from "@shopify/polaris";


// Images:
import imageSmartContract1DisplayExample from "./images/smart-contract-1-display-example.png"



function SmartContractDetails(props) {  
  // Variables:
  const smartContract = props.smartContract;

  // Smart contract details:
  const contractName = props.contractName;
  const contractSymbol = props.contractSymbol;
  const handleContractNameChange = props.handleContractNameChange;
  const handleContractSymbolChange = props.handleContractSymbolChange;
  const handleSubmit = props.handleSubmit;


  if (smartContract === null || !['', null].includes(smartContract.contract_step)) {
    return (
      <></>
    );
  }



  return (
    <>
      {/* Smart contract status */}
      <Layout.Section>
        <Card title="Smart Contract (Not Deployed)" sectioned>
          <p>Smart Contracts allow your Digital Collectibles to be assigned to your store. Deploy your Smart Contract before configuring Digital Collectible products.</p>
        </Card>
      </Layout.Section>



      <Layout.Section>
        <Card title="Smart Contract Details" sectioned>
          <p>This is how your store will be represented on the blockchain.</p>

          <Form onSubmit={handleSubmit}>
            <FormLayout>

              <TextField
                name="contractName"
                value={contractName}
                onChange={handleContractNameChange}
                label="Contract Name"
                type="text"
                helpText={(
                  <>
                    <ul>
                      <li>Use the same name as your store (e.g. if you store is called Mint My Ride, your Contract Name should be: MintMyRide).</li>
                      <li>No spaces, alphanumeric characters only (A-Z, 0-9).</li>
                      <li>Used as your store's collection name on OpenSea, please check the OpenSea collection name is available otherwise a number will be appended (e.g. MintMyRide01).</li>
                    </ul>
                  </>
                )}
              />

              <TextField
                name="contractSymbol"
                value={contractSymbol}
                onChange={handleContractSymbolChange}
                label="Contract Symbol"
                type="text"
                helpText={
                  <ul>
                    <li>Create a one to three-letter code which identifies your store. We recommend a code that resembles your Contract Name (e.g. For MintMyRide, the Contract Symbol could be: MMR).</li>
                    <li>No spaces, alphanumeric characters only (A-Z, 0-9).</li>
                  </ul>
                }
              />

              <div>
                <p>Smart Contracts can only be deployed once, please ensure all details are correct.</p>
              </div>

              <div>
                <p>It can take up to 24hrs to create your Smart Contract. You'll receive an email notification when it has been deployed.</p>
              </div>

              <Button primary submit>Deploy Smart Contract</Button>
            </FormLayout>
          </Form>
        </Card>
      </Layout.Section>



      {/* Display examples */}
      <Layout.Section secondary>
        <Card title="Display examples" sectioned>
          <p>Smart Contract details will display on Polygonscan, OpenSea and your Digital Collectibles.</p>

          <div style={{paddingTop: '30px'}}>
            <Image
              src={imageSmartContract1DisplayExample}
              width="100%"
            ></Image>
          </div>
        </Card>
      </Layout.Section>

    </>
  );

}



export default SmartContractDetails;