import React, { useState, useCallback } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";
import {
  Button,
  Caption,
  Card,
  DropZone,
  Stack,
  TextField,
  Thumbnail,
} from "@shopify/polaris";
import styles from "./ImageManagementManual.module.css";
import { NoteMinor } from '@shopify/polaris-icons';

// Images:


const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function ImageManagementManual(props) {
  // console.log('ImageManagementManual:::2022-07-29');
  // console.log('props:::2022-07-29', props);
  const app = props.app;
  const order = props.order;
  const product = props.product;


  var productImage = {}

  if (product.image_details === undefined) {
    return (
      <>Loading...</>
    );
  }

  if (product.image_details === null) {
    productImage.url = "...";
    return (

      <>
        <Card title="NFT Image (Displays on frame)" sectioned>

          <div style={{width: '50%'}}>
            <TextField
              value="Manual"
              label="Fulfilment Type"
              disabled
            />
          </div>

          {/* Image File */}
          <div style={{marginTop: '20px'}}>
            <p><b>Image File</b></p>
            <div style={{maxWidth: '340px'}}>
              <DropZone1 
                onFileUpload={props.onFileUpload}
                product={product}
              ></DropZone1>
            </div>
            <p>No file uploaded.</p>
          </div>

          {/* NFT Image Preview */}
          <div style={{marginTop: '20px'}}>
            <p><b>NFT Image Preview</b></p>
            <div className={styles.nftImagePreviewFrame}>
            </div>
            <p>Upload an image to generate preview.</p>
          </div>

          <div style={{marginTop: '30px'}}>
            <Button disabled>Remove Image</Button>
          </div>


        </Card>
      </>
    )
  }



  productImage.url = `https://${product.image_details.base_url}/${product.image_details.file_path}`;
  let boolLockRemoveImage = (product.claim_status === 'claimed');
  return (
    <>
      <Card title="Digital Collectible Image (Displays on frame)" sectioned>

        <div style={{width: '50%'}}>
          <TextField
            // name=""
            // value={store.fulfilment.nft_details_type}
            value="Manual"
            // onChange={}
            label="Fulfilment Type"
            // autoComplete="off"
            disabled
          />
        </div>


        {/* Image File */}
        <div style={{marginTop: '20px'}}>
          <p><b>Image File</b></p>
          <div>
            <img
              src={ productImage.url }
              className={styles.productImage}
            ></img>
          </div>
          <p>{ productImage.url }</p>
        </div>


        {/* Image Preview */}
        <div style={{marginTop: '20px'}}>
          <p><b>Image Preview</b></p>
          <div className={styles.nftImagePreviewFrame}>
            <img
              src={ productImage.url }
              className={styles.nftImagePreview}
            ></img>
          </div>
          <p>Image preview only.</p>
        </div>


        <div style={{marginTop: '30px'}}>
          <Button 
            destructive 
            onClick={handleClearImage}
            disabled={boolLockRemoveImage}
          >Remove Image</Button>
        </div>

      </Card>
    </>
  );

  function DropZone1(props) {
    const [file, setFile] = useState();
    const onFileUpload = props.onFileUpload;
    const product = props.product;
  
    const handleDropZoneDrop = useCallback(
      (_dropFiles, acceptedFiles, _rejectedFiles) => {
        setFile((file) => acceptedFiles[0]);
  
        var formData = new FormData();
        formData.append("myFile", acceptedFiles[0], acceptedFiles[0].name);
        formData.append("name", "Kain");
        formData.append("product_uuid", product.uuid);
        formData.append("shopify_order_id", order.payload_order.id);
  
        const url = "/api/v0/shopify/products/update_image_details";
        let myUrl = new URL(url, BASE_URL_RAILS);
    
        getSessionToken(app).then((data) => {
          let token = data;
  
          var config = {
            method: "POST",
            url: myUrl,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
            data: formData,
          };
  
          axios(config)
            .then(function (response) {
              onFileUpload(response.data.order_product);
            })
            .catch(function (error) {
              console.log(error);
            });
        });
  
      },
      []
    );
  
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  
    const fileUpload = !file && <DropZone.FileUpload />;
    const uploadedFile = file && (
      <Stack>
        <Thumbnail
          size="small"
          alt={file.name}
          source={
            validImageTypes.includes(file.type)
              ? window.URL.createObjectURL(file)
              : NoteMinor
          }
        />
        <div>
          {file.name} <Caption>{file.size} bytes</Caption>
        </div>
      </Stack>
    );
  
    let boolLockDropZone = (product.claim_status === 'claimed');
    return (
      <>
        <DropZone 
          allowMultiple={false} 
          onDrop={handleDropZoneDrop}
          disabled={boolLockDropZone}
        >
          {uploadedFile}
          {fileUpload}
        </DropZone>
      </>
    );
  }


  function handleClearImage() {
    getSessionToken(app).then((data) => {
      let token = data;

      const url = `/api/v0/shopify/products/update_image_details_clear`;
      let myUrl = new URL(url, BASE_URL_RAILS);

      var config = {
        method: 'post',
        url: myUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {product_uuid: product.uuid}
      }

      axios(config).then((response) => {
        props.onClearImage(response.data.payload.product);
      })

    });
  }


}

export default ImageManagementManual