import ShopifyImages from "./ShopifyImages";
import ManualFulfilmentImages from "./ManualFulfilmentImages";
import {
  Layout,
} from "@shopify/polaris";


function ImageManagement(props) {
  var app = props.app;
  var order = props.order;
  var product = props.product;
  var store = props.store;
  const onFileUpload = props.onFileUpload;
  const onClearImage = props.onClearImage;
  

  return (
    <>
      <MyShopifyImages></MyShopifyImages>
    </>
  );



  function MyShopifyImages() {
    if (store.fulfilment.image_type === "automatic") {
      return (
        <Layout.Section>
          <ShopifyImages product={product}></ShopifyImages>
        </Layout.Section>  
      );      
    }
    
    if (store.fulfilment.image_type === "manual") {
      return (
        <Layout.Section>
          <ManualFulfilmentImages
            app={app}
            onClearImage={onClearImage}
            onFileUpload={onFileUpload}
            order={order}
            product={product}
          ></ManualFulfilmentImages>
        </Layout.Section>
      );
    }
  }

}

export default ImageManagement;