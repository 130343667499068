import React, { useEffect, useState } from "react";
import { 
  Link,
  useNavigate,
} from "react-router-dom";



import { getSessionToken } from "@shopify/app-bridge-utils";
import { Redirect } from '@shopify/app-bridge/actions';
import axios from "axios";
import Template from "./Template";


const img = "https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg";
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function sumTotalProducts(lineItems) {
  var totalProducts = 0;
  lineItems.forEach((l) => {
    totalProducts += l.quantity;
  });
  return totalProducts;
}



function TestNft(props) {
  // console.log("TestNft::2022-09-01");
  // console.log("props::2022-09-01", props);
  const app = props.app;
  const shop = props.shop;
  const redirect = Redirect.create(app);
  const navigate = useNavigate();

  const [tableRows, setTableRows] = useState([]);
  const [countClaimedNfts, setCountClaimedNfts] = useState(0);
  const [smartContract, setSmartContract] = useState(null);


  useEffect(() => {

    checkSessionActive();
    fetchData();

    async function fetchData() {
      var token;
      await getSessionToken(app).then((data) => {
        // console.log("getSessionToken()::2022-06-02");
        token = data;
        // console.log("token::2022-06-02", token);
  
        // const baseURL = props.app.localOrigin;
        const url = "/api/v0/shopify/page_contents/test_nft";
        let myUrl = new URL(url, BASE_URL_RAILS);
        // console.log("myUrl::2022-06-02", myUrl);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          // console.log("result::2022-10-13", result);
          const payload = result.payload;
  
          var tableRowsTemp = prepareTableRows(payload.new_orders);
          setTableRows(tableRowsTemp);
          // console.log('tableRowsTemp:::2022-07-27', tableRowsTemp);


          // Set claimed NFT count:
          setCountClaimedNfts(payload.test_nfts_created);

          // Set smart contract:
          setSmartContract(payload.smart_contract);

        });
  
  
      });
    }

    function checkSessionActive() {
      getSessionToken(app).then((data) => {
        let token = data;
        const url = "/api/v0/shopify/sessions/check_session_active";
        let myURL = new URL(url, BASE_URL_RAILS);
        
        var config = {
          method: 'post',
          url: myURL,
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          // data : data
        };
        
        axios(config).then((response) => {
          // console.log("response:::2022-07-19", response);
          var payload = response.data.payload;
          if (payload.message === "Session has expired. Try refreshing your page.") {
            // alert(payload.message);
            redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      })    
    }
  
    
  }, []);



  function importOrders() {
    getSessionToken(app).then((data) => {
      let token = data;
      const url = "/api/v0/shopify/page_contents/test_nft/import_orders";
      let myURL = new URL(url, BASE_URL_RAILS);
      
      var config = {
        method: 'post',
        url: myURL,
        headers: { 
          Authorization: `Bearer ${token}`,
        },
        // data : data
      };
      
      axios(config).then((response) => {
        // console.log("response:::2022-10-13", response);
        const payload = response.data.payload;
  
        var tableRowsTemp = prepareTableRows(payload.new_orders);
        setTableRows(tableRowsTemp);

      })
      .catch(function (error) {
        console.log(error);
      });
    })    
  };



  function prepareTableRows(payloadOrders) {

    var tableRowsTemp = [];
    payloadOrders.forEach(o => {

      let email = o.my_email || 'No email';

      tableRowsTemp.push([
        `#${o.my_order_number}`,
        o.my_created_at,
        email,
        sumTotalProducts(o.payload_order.line_items),
        <Link
          key={o.shopify_order_id}
          to={`/test_nft/${o.shopify_order_id}`}
        >
          View
        </Link>,
      ])
    });

    return tableRowsTemp;
  }



  return (
    <>
      <Template
        tableRows={tableRows}
        countClaimedNfts={countClaimedNfts}
        importOrders={importOrders}
        smartContract={smartContract}
      ></Template>
    </>
  );

}

export default TestNft;