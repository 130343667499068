import React from "react";

import {
  Link,
  useNavigate,
} from "react-router-dom";

import {
  Button,
  Card,
  Layout,
  Page,
  ProgressBar,
} from "@shopify/polaris";

// CSS:
import styles from "./css/Template.module.css";

// Images:
import imageTestNft from "./images/test-dc.png";
import imageSmartContract from "./images/smart-contract.png";
import imageNftSettings from "./images/dc-settings.png";
import imageNftProducts from "./images/nft-products.png";

import imageTaskNotStarted from "./images/task-not-started.png";
import imageTaskComplete from "./images/task-complete.png";

// Components:




function Template(props) {
  const navigate = useNavigate();
  // navigate("/new_orders", { replace: true });
  const app = props.app;
  const startupGuide = props.startupGuide;
  const taskCount = props.taskCount;
  const taskTotal = props.taskTotal;



  function StartupGuideProgress(props) {
    const startupGuide = props.startupGuide;
    return (
      <>
        <p>{ taskCount } of { taskTotal } tasks complete</p>
        <div className="pb-10">
          <ProgressBar progress={ (taskCount / taskTotal) * 100 } size="small" />
        </div>
      </>
    )
  }




  if (startupGuide === null) {
    return (
      <Page>
        <Layout>
          <Layout.Section>
            <Card sectioned title="Startup guide">
              <p>Pending...</p>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    )
  }



  function StartupGuide1(props) {
    const startupGuide = props.startupGuide;
    let imageTick = imageTaskNotStarted;

    if (startupGuide.step_1.is_complete) {
      imageTick = imageTaskComplete;
    }

    return (
      <div className="pb-10">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={imageTick} style={{marginRight: '5px'}}></img>
          <p><b>Create a Test Collectible</b></p>
        </div>
      </div>
    )
  }



  function StartupGuide2(props) {
    const startupGuide = props.startupGuide;
    let imageTick = imageTaskNotStarted;

    if (startupGuide.step_2.is_complete) {
      imageTick = imageTaskComplete;
    }

    return (
      <div className="pb-10">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={imageTick} style={{marginRight: '5px'}}></img>
          <p><b>Deploy your Smart Contract</b></p>
        </div>
      </div>
    )
  }



  function StartupGuide3(props) {
    const startupGuide = props.startupGuide;
    let imageTick = imageTaskNotStarted;

    if (startupGuide.step_3.is_complete) {
      imageTick = imageTaskComplete;
    }

    return (
      <div className="pb-10">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={imageTick} style={{marginRight: '5px'}}></img>
          <p><b>Configure your Collectibles Settings</b></p>
        </div>
      </div>
    )
  }



  function StartupGuide4(props) {
    const startupGuide = props.startupGuide;
    let imageTick = imageTaskNotStarted;

    if (startupGuide.step_4.is_complete) {
      imageTick = imageTaskComplete;
    }

    return (
      <div className="pb-10">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={imageTick} style={{marginRight: '5px'}}></img>
          <p><b>Set up your first Digital Collectible product</b></p>
        </div>
      </div>
    )
  }



  return (
    <>
      <Page>
        <Layout>
          <Layout.Section>
            <Card sectioned title="Startup guide">

              <StartupGuideProgress
                startupGuide={startupGuide}
              ></StartupGuideProgress>

              <hr></hr>

              {/* Create a Test Collectible */}
              <div style={{marginTop: '50px'}}>
                <Layout>
                  <Layout.Section oneHalf>
                    <div>

                      <StartupGuide1
                        startupGuide={startupGuide}
                      ></StartupGuide1>

                      <div className="pb-30">
                        <p>Create free Test Collectibles on our Certify Smart Contract. This lets you preview Collectible settings, experience the same claiming process as your customers, and view the Digital Collectible on an OpenSea collection.</p>
                      </div>
                      
                      <Link to={`/test_nft`} style={{textDecoration: 'unset'}}>
                        <Button primary>Create Test Collectible</Button>
                      </Link>

                    </div>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <div style={{textAlign: 'center'}}>

                      <img src={imageTestNft} width="235px"></img>
                    
                    </div>
                  </Layout.Section>
                </Layout>
              </div>


              {/* Smart Contract Setup */}
              <div style={{marginTop: '50px'}}>
                <Layout>
                  <Layout.Section oneHalf>
                    <div>

                      <StartupGuide2
                        startupGuide={startupGuide}
                      ></StartupGuide2>

                      <div className="pb-30">
                        <p>Smart Contracts allow your Digital Collectibles to be assigned to your store. You need to set this up and deploy it before you begin configuring your own Digital Collectible products.</p>
                      </div>


                      <Link to={`/smart_contract`} style={{textDecoration: 'unset'}}>
                        <Button primary>Smart Contract Setup</Button>
                      </Link>

                    </div>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <div style={{textAlign: 'center'}}>

                      <img src={imageSmartContract} width="235px"></img>
                    
                    </div>
                  </Layout.Section>
                </Layout>
              </div>



              {/* NFT Settings */}
              <div style={{marginTop: '50px'}}>
                <Layout>
                  <Layout.Section oneHalf>
                    <div>

                      <StartupGuide3
                        startupGuide={startupGuide}
                      ></StartupGuide3>

                      <div className="pb-30">
                        <p>Choose between Manual or Automatic options for your Digital Collectibles attributes (artwork, details and metadata) according to your particular product offerings.</p>
                      </div>

                      <Link to={`/nft_settings`} style={{textDecoration: 'unset'}}>
                        <Button primary>Collectibles Settings</Button>
                      </Link>
                    </div>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <div style={{textAlign: 'center'}}>

                      <img src={imageNftSettings} width="235px"></img>
                    
                    </div>
                  </Layout.Section>
                </Layout>
              </div>



              {/* Product Setup */}
              <div style={{marginTop: '50px'}}>
                <Layout>
                  <Layout.Section oneHalf>
                    <div>

                      <StartupGuide4
                        startupGuide={startupGuide}
                      ></StartupGuide4>

                      <div className="pb-30">
                        <p>Once you're happy with your Collectible Settings, it's time to create an Digital Collectible offering.</p>
                      </div>

                      <Link to={`/product_setup`} style={{textDecoration: 'unset'}}>
                        <Button primary>Product Setup</Button>                  
                      </Link>
                    </div>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <div style={{textAlign: 'center'}}>

                      <img src={imageNftProducts} width="235px"></img>
                    
                    </div>
                  </Layout.Section>
                </Layout>
              </div>



            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

}

export default Template;