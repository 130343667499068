import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Image,
  Layout,
  Page,
} from "@shopify/polaris";

// CSS:
import styles from "./Template.module.css";

// Components:
import ClaimLink from "./ClaimLink";
import CustomMetadata from "./CustomMetadata";
import ImageManagement from "./ImageManagement";
import NftDetails from "./NftDetails";





function Template(props) {
  const app = props.app;
  const params = props.params;
  const store = props.store;
  const order = props.order;
  const product = props.product;
  const orderId = props.orderId;
  const nftDetails = props.nftDetails;

  const onClearImage = props.onClearImage;
  const onFileUpload = props.onFileUpload;

  const resetClaimStatus = props.resetClaimStatus;

  // Component: ClaimLink
  const formEmail = props.formEmail;
  const handleFormSubmit = props.handleFormSubmit;
  const handleFormEmailChange = props.handleFormEmailChange;
  const handleGenerateClaimLink = props.handleGenerateClaimLink;



  if (store === null || order === null || product === null) {
    return (
      <>
        <Page>
          <Layout>
            <Layout.Section>
              <Card title="Loading" sectioned>
                <p>Loading...</p>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </>
    )
  }



  console.log('product.shopify_images:::2022-07-29', product.shopify_images);
  var tempImages = product.shopify_images || [];
  const shopifyImages = tempImages.map((i, index) =>
    <img
      key={index}
      src={`https://${i.base_url}/${i.file_path}`} 
      className={styles.productImage}></img>
  )


  return (
    <>
      <Page>
        <Layout>



          {/* Page Header */}
          <Layout.Section>
            <div className={styles.headerSection}>
              <div style={{marginRight: '12px'}}>
                <Link to={`/new_orders/${orderId}`} style={{textDecoration: 'none'}}>
                  <Button>Back</Button>
                </Link>
              </div>
              <div style={{marginRight: '12px'}}>
                <p>New Orders / Order #{order.payload_order.order_number} / {product.payload_product.title}</p>
              </div>
            </div>
          </Layout.Section>



          {/* Product Details */}
          <Layout.Section>
            <Card title="Product Details" sectioned>

              <p><b>Name: </b>{product.payload_product.title}</p>
              <p><b>Price: </b>${product.payload_line_item.price}</p>
              <p><b>Currency: </b>{order.payload_order.currency}</p>
              <p><b>SKU: </b>{product.payload_line_item.sku}</p>

              <br></br>

              <p><b>Description</b></p>
              <p>{product.payload_product.body_html}</p>

              <br></br>

              <p><b>Product Images</b></p>
              {shopifyImages}
            </Card>
          </Layout.Section>



          {/* NFT Image */}
          <ImageManagement 
            app={app}
            onClearImage={onClearImage}
            onFileUpload={onFileUpload}
            order={order}
            product={product}
            store={store}
          ></ImageManagement>


          
          {/* Details */}
          <NftDetails 
            app={app}
            store={store}
            order={order}
            product={product}
            nftDetails={nftDetails}
          ></NftDetails>



          <CustomMetadata
            app={app}
            store={store}
            product={product}
          ></CustomMetadata>



          <ClaimLink
            app={app}
            store={store}
            order={order}
            product={product}
            resetClaimStatus={resetClaimStatus}

            // Form:
            formEmail={formEmail}
            handleFormSubmit={handleFormSubmit}
            handleFormEmailChange={handleFormEmailChange}
            handleGenerateClaimLink={handleGenerateClaimLink}
          ></ClaimLink>

        </Layout>

      </Page>
    </>
  );

}

export default Template;