import React, { useEffect, useState } from "react";

import {
  Card,
  ProgressBar,
} from "@shopify/polaris";
import { getSessionToken } from "@shopify/app-bridge-utils";


// Components:

const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;


function BillingPeriod(props) {
  console.log('BillingPeriod(props)::2022-06-05');
  const app = props.app;

  //State variables
  const [isLoading, setLoading] = useState(true);
  const [billingDay, setBillingDay] = useState(0);
  const [billingDayProgress, setBillingDayProgress] = useState(0);
  const [billingDateEnd, setBillingDateEnd] = useState(0);

  useEffect(() => {
    prepareBilling();
    setLoading(false);
  }, []);

  if (isLoading) return <p>Loading...</p>;

  return (
    <Card title="Billing Period" sectioned>
      <p>
        Day <b>{billingDay}</b> of {30}
      </p>

      <ProgressBar progress={billingDayProgress} />

      <p>Renews {billingDateEnd}</p>
    </Card>
  );

  async function prepareBilling() {
    const url = "/billing/my_billing_period";
    let myUrl = new URL(url, BASE_URL_RAILS);

    await getSessionToken(app).then((data) => {
      let token = data;

      fetch(myUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("result::2022-06-05", result);
          setBillingDay(result.billing_period_day);
          setBillingDayProgress((result.billing_period_day / 30.0) * 100);
          setBillingDateEnd(result.billing_period_end_date);
        });
    });
  }
}

export default BillingPeriod;